import React from 'react';
import { GlobalRoutes } from './auto-routes';
import './styles/App.less';
import DocumentTitle from 'react-document-title';
import { useI18nHook } from './i18n';


function App() {
  const i = useI18nHook()
  return (
    <div className="App">
      <DocumentTitle title={i("开发者中心")}>
        <GlobalRoutes/>
      </DocumentTitle>
    </div>
  );
}

export default App;
